import { injectable } from 'inversify';

export interface IInteractorService {
  listen(canvas: HTMLCanvasElement): void;
  disconnect(): void;
  connect(): void;
  destroy(): void;
  on(event: IInteractorEvent, args?: unknown): void;
}

export enum IInteractorEvent {
  PANSTART = 'PANSTART',
  PANEND = 'PANEND',
  PANMOVE = 'PANMOVE',
  PINCH = 'PINCH',
  KEYDOWN = 'KEYDOWN',
  KEYUP = 'KEYUP',
  HOVER = 'HOVER',
}

@injectable()
export class InteractorService implements IInteractorService {
  public listen(canvas: HTMLCanvasElement): void {}
  public on(event: IInteractorEvent, args?: unknown): void {}
  public connect() {}
  public disconnect() {}
  public destroy() {}
}
